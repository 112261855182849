<script>
export default {
  name : 'Modal',
  props : ["modalData"],
  data() {
    return {
      data : this.modalData,
      showModal : false,
    }
  },
  mounted() {
//    console.log(this.modalData)
    this.showModal = true;
  },
  methods: {
    openModal() {
      window.history.pushState({}, '', '#modal');
      window.onpopstate = history.onpushstate = () => {
        this.showModal = false
      }
    },

    closeModal() {
      this.$emit('close');
      window.history.back()
    },
  }
}
</script>

<template>
  <b-modal
      v-model="showModal"
      id="modal-box"
      @show="openModal"
      @hidden="closeModal"
      centered
      title="상세정보"
      title-class="font-16 fw-bold"
      hide-footer
  >
    <div class="row mb-2 align-items-center">
      <div class="mb-2">
        <div class="mb-1 fw-semibold">회원명</div>
        <div class="form-control bg-light">
          {{ data.member_name }}
          <img v-if="data.member_social" :src="require(`@/assets/images/social/icon_${data.member_social.toLowerCase()}.png`)" class="align-text-top" height="16" />
        </div>
      </div>
      <div class="my-2">
        <div class="mb-2 fw-semibold">휴대폰</div>
        <div class="form-control bg-light">{{ data.member_phone }}&nbsp;</div>
      </div>
      <div class="my-2">
        <div class="mb-2 fw-semibold">구분</div>
        <div class="form-control bg-light">{{ data.kind }}&nbsp;</div>
      </div>
      <div class="my-2">
        <div class="mb-2 fw-semibold">메시지</div>
        <div class="form-control bg-light">{{ data.message }}&nbsp;</div>
      </div>
      <div class="my-2">
        <div class="mb-1 fw-semibold">구조요청시간</div>
        <div class="form-control bg-light">{{ data.register_date }}&nbsp;</div>
      </div>
      <div class="my-2">
        <div class="mb-1 fw-semibold">안전확인</div>
        <div class="form-control bg-light">{{ data.safe_date }}&nbsp;</div>
      </div>
      <div class="my-2">
        <div class="mb-1 fw-semibold">안전확인자</div>
        <div class="form-control bg-light">{{ data.safer_name }}&nbsp;</div>
      </div>
      <div class="my-2">
        <div class="mb-1 fw-semibold">안전확인시간</div>
        <div class="form-control bg-light">{{ data.safe_date }}&nbsp;</div>
      </div>
      <div class="my-2">
        <div class="mb-1 fw-semibold">상태</div>
        <div class="form-control bg-light"><span :class="data.status_class">{{ data.status_message }}</span>&nbsp;</div>
      </div>
      <div class="my-2">
        <div class="mb-1 fw-semibold">요청위치</div>
        <div class="form-control bg-light"><span v-html="data.action_location"></span></div>
      </div>
    </div>
  </b-modal>
</template>
